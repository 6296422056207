import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet molt petit de 0,5 a 2 cm d’altura en forma de disc aplanat una mica còncau i d’uns 0,5 a 1,5 cm de diàmetre. És de color blanc i presenta petits punts negres, que són els porus repartits uniformement per on surten les espores. El peu és cilíndric i està enfonsat al substrat. Les ascospores són de color marró en massa, forma arronyonada, llises, envoltades d’una capa gelatinosa, de 18-26 x 6-12 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      